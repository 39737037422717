import React from 'react';
import styled from 'styled-components';
import { P, SecondaryP } from './Typography';

const Wrapper = styled.div<{ imageSrc: string }>`
  display: flex;
  flex-direction: column;
  padding: 170px 20px 30px;
  height: calc(334px - 170x - 30px);
  background-image: url(${({ imageSrc }) => imageSrc});
  background-color: #fff;
  background-repeat: no-repeat;
  border-radius: 12px;
  border: 1px solid #eee;
  background-size: cover;
`;

const Label = styled(P)`
  font-weight: 700;
  margin-bottom: 7px;
`;

const Description = styled(SecondaryP)`
  color: #495b6c;
  line-height: 25px;
`;

interface ThisComponentProps {
  label: string;
  description: string;
  imageSrc: string;
}

const ShipmentCard: React.FunctionComponent<ThisComponentProps> = ({
  label,
  description,
  imageSrc,
}) => (
  <Wrapper imageSrc={imageSrc}>
    <Label>{label}</Label>
    <Description>{description}</Description>
  </Wrapper>
);

export default ShipmentCard;
