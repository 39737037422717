import React from 'react';
import styled from 'styled-components';
import { H1, H3, P } from '@/components/Typography';
import ButtonDefault from '@/components/ButtonDefault';
import viewport1Img from '../../assets/img/shippingManagementVp1.png';
import viewport2Img1 from '../../assets/img/shipmentManagementVp2-1.png';
import viewport2Img2 from '../../assets/img/shipmentManagementVp2-2.png';
import viewport2Img3 from '../../assets/img/shipmentManagementVp2-3.png';
import viewport2Img4 from '../../assets/img/shipmentManagementVp2-4.png';
import Section from '@/components/Section';
import FirstViewport from '@/components/FirstViewport';
import BigPurpleCard from '@/components/BigPurpleCard';
import { ShippingManagementPageContent } from '@/pages/shipping-management';
import TickedItems from '@/components/TickedItems';
import ShipmentCard from '@/components/ShipmentCard';

const FirstVpWrapper = styled.div`
  background: linear-gradient(118.47deg, #f8f9fa 3.57%, #e4e6f0 100%);
`;

const SecondViewport = styled(Section)`
  background: linear-gradient(#ffffff, #e7edf3);
  padding-bottom: 80px;
  @media (max-width: 800px) {
    padding-bottom: 1rem;
  }
`;

const DefaultViewport = styled(SecondViewport)<{
  withoutPaddingTop?: boolean;
  withoutPaddingBottom?: boolean;
}>`
  background: transparent;
  ${({ withoutPaddingTop }) => withoutPaddingTop && `padding-top: 0;`}
  ${({ withoutPaddingBottom }) => withoutPaddingBottom && `padding-bottom: 0;`}
`;

const StyledDescription = styled(P)`
  color: #495b6c;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 1rem;
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

const StyledButtonDefault = styled(ButtonDefault)`
  margin-top: 1.5rem;
`;

interface ShippingManagementProps {
  content: ShippingManagementPageContent;
}

const ShippingManagementContainer: React.FunctionComponent<
  ShippingManagementProps
> = ({ content: { viewport1, viewport2, viewport3 } }) => (
  <>
    <FirstVpWrapper>
      <FirstViewport img={viewport1Img}>
        <H1 style={{ marginBottom: `1.5rem` }}>
          <span className="accent-text">{viewport1.title1}</span>
          {` `}
          {viewport1.title2}
        </H1>
        <StyledDescription>{viewport1.description}</StyledDescription>
        <TickedItems textColor="#495B6C" items={viewport1.tickedDescription} />
        <StyledButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </StyledButtonDefault>
      </FirstViewport>
    </FirstVpWrapper>

    <SecondViewport>
      <H3 style={{ maxWidth: 768, margin: `0 auto`, textAlign: `center` }}>
        {viewport2.title}
      </H3>
      <CardWrapper>
        <ShipmentCard
          label={viewport2.cards[0].label}
          description={viewport2.cards[0].description}
          imageSrc={viewport2Img1}
        />
        <ShipmentCard
          label={viewport2.cards[0].label}
          description={viewport2.cards[0].description}
          imageSrc={viewport2Img2}
        />
        <ShipmentCard
          label={viewport2.cards[0].label}
          description={viewport2.cards[0].description}
          imageSrc={viewport2Img3}
        />
        <ShipmentCard
          label={viewport2.cards[0].label}
          description={viewport2.cards[0].description}
          imageSrc={viewport2Img4}
        />
      </CardWrapper>
    </SecondViewport>

    <DefaultViewport>
      <BigPurpleCard
        title={viewport3.title}
        subTitle={viewport3.subTitle}
        leftColumnTitle={viewport3.leftColumnTitle}
        rightColumnTitle={viewport3.rightColumnTitle}
        leftColumn={viewport3.leftColumn}
        rightColumn={viewport3.rightColumn}
        bottomText={viewport3.bottomText}
        button={viewport3.button}
      />
    </DefaultViewport>
  </>
);

export default ShippingManagementContainer;
